<template>
<!-- <p>Gráfica Presidente Suplente</p> -->
<div class="componente-grafica" v-if="barras"> 
  <h4> {{titulo}}</h4>
  <column-chart 
  :data="[['Hombres',hombres],['Mujeres',mujeres]]"
  :colors="['#43e287', '#e28743']"
  label="Total"
  width="200px" height="200px"
  ></column-chart>
</div>

<div class="componente-grafica" v-if="pie"> 
  <h4> {{titulo}}</h4>
  <pie-chart 
  :data="[['Hombres',hombres],['Mujeres',mujeres]]"
  :colors="['#43e287', '#e28743']"
  label="Total"
  width="200px" height="200px"
  />
</div>


  
</template>

<script>
export default {
  props:{
    hombres: { type: Number, default:6},
    mujeres: { type: Number, default:6},
    titulo: { type: String },
    pie: { type: Boolean, default: false },
    barras: { type: Boolean, default: false}
  },

}
</script>

<style lang="scss" scoped>

    .componente-grafica{
        width:45%;
        display:flex; flex-direction: column; align-items:center; justify-content: center;
        
        & h4{
            font-size:14px;
            padding:12px 12px;
        }
    }
</style>